import "./assets/css/styles.css";
import Airdrop from "./components/Airdrop/Airdrop";
import Banner from "./components/Banner/Banner";
// import Buytoken from "./components/Buytoken/Buytoken";
// import Countdown from "./components/Countdown/Countdown";
import HowToPlay from "./components/HowToPlay/HowToPlay";
import Platfroms from "./components/Platforms/Platfroms";
// import Privatesale from "./components/Privatesale/Privatesale";
import PublicSale from "./components/PublicSale/PublicSale";
import Quote from "./components/Quote/Quote";
import Reviews from "./components/Reviews/Reviews";
import Roadmap from "./components/Roadmap/Roadmap";
import ScocialToken from "./components/SocialToken/ScocialToken";
import Team from "./components/Team/Team";
import TokenDistribution from "./components/TokenDistribution/TokenDistribution";
import Welcome from "./components/Welcome/Welcome";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";

import { useRef } from "react";

function App() {
  const buyTokenRef = useRef(null);

  return (
    <div className="App">
      <Header />
      <Banner buyTokenRef={buyTokenRef}/>
      <Platfroms />
      <Airdrop />
      {/* <Countdown /> */}
      {/* <Privatesale /> */}
      {/* <Buytoken ref={buyTokenRef} /> */}
      {/* <PublicSale />
      <Welcome />
      <HowToPlay />
      <ScocialToken buyTokenRef={buyTokenRef} />
      <TokenDistribution buyTokenRef={buyTokenRef} />
      <Team />
      <Quote buyTokenRef={buyTokenRef} />
      <Reviews />
      <Roadmap /> */}
      <Footer />
    </div>
  );
}

export default App;
