import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, Box } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react"
import {
  ThirdwebProvider, metamaskWallet,
  coinbaseWallet,
  walletConnect,
  embeddedWallet,
} from "@thirdweb-dev/react";

const theme = extendTheme({
  fonts: {
    body: "Comfortaa, sans-serif",
    heading: "Comfortaa, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
    <ThirdwebProvider
      activeChain={"ethereum"}
      clientId="d3ef52c9a18c17eba1e1fc43d862671c"
      supportedWallets={[
        // metamaskWallet(),
        // coinbaseWallet(),
        // walletConnect(),
        embeddedWallet(),
      ]}
    >
      <Box bg={'white'}>
      <App />
      </Box>
    </ThirdwebProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
