import { useState, useEffect } from "react";
import { BigNumber } from "ethers";
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  useShowConnectEmbed,
  useWallet,
  ConnectEmbed,
  ConnectWallet,
} from "@thirdweb-dev/react";
import {
  Center,
  VStack,
  Text,
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
  Spinner
} from "@chakra-ui/react";

import data2016 from "./JSON/output2016.json";
import data2020 from "./JSON/output2020.json";

export default function Airdrop() {
  const sheetDB = "https://sheetdb.io/api/v1/0grt0sp885xdc";

  const showConnectEmbed = useShowConnectEmbed();
  const activeWallet = useWallet();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [email, setEmail] = useState("default");
  const [userAddress, setUserAddress] = useState("default");
  const [userData, setUserData] = useState(null);
  const [userClaimed, setUserClaimed] = useState(undefined);
  const [clickClaimed, setClickClaimed] = useState(false);
  const [fetchedEmail, setFetchedEmail] = useState(false);

  const [loadingButton, setLoadingButton] = useState();

  function formatDate(date) {
    const timeZone = 'America/Los_Angeles';
    const zonedDate = toZonedTime(date, timeZone);
    const pattern = 'MM/dd/yyyy hh:mm:ss a';
    return format(zonedDate, pattern, { timeZone });
  }

  function renderWhatToDisplay() {
    if (userClaimed) {
      return (
        <>
          <Button onClick={onOpen} colorScheme="blackAlpha">
            Export Your Wallet
          </Button>

          <Text mt="10" color="black" as="b" fontSize="xl" textAlign={"center"}>
            Congratulations {userData["FullName"]}, you have claimed{" "}
            <Text color="#0083FD" as="span">
              {(
                BigNumber.from(userData["20%TokenReleaseAtTGE"]) /
                10 ** 18
              ).toLocaleString()}{" "}
            </Text>{" "}
            out of your{" "}
            <Text color="#0083FD" as="span">
              {(
                BigNumber.from(userData["TotalQtyTokenAirdrop"]) /
                10 ** 18
              ).toLocaleString()}{" "}
            </Text>{" "}
            tokens.
          </Text>

          <Text mt="10" color="black" fontSize="md" textAlign={"center"}>
            The remaining tokens will be unlocked and sent to your claim dashboard
            <br />
            every week based on the vesting schedule above.
            <br />
          </Text>

          <Button
            onClick={() => window.open("https://www.team.finance/claim")}
            bgColor="#0066ff"
            color="white"
            mt="10"
            size="lg"
            _hover={{ bgColor: "#0052cc" }}
          >
            Claim Dashboard
          </Button>

          <Text
            mt="10"
            color="#0083FD"
            as="b"
            fontSize="xl"
            textAlign={"center"}
          >
            To Claim Locked Tokens:
          </Text>

          <Text mt="10" color="#FF0000" fontSize="md" textAlign={"center"}>
            1. Export your wallet to get your Private Key. Do NOT share with
            anyone.
          </Text>

          <Text mt="3" color="#FF0000" fontSize="md" textAlign={"center"}>
            2. Download MetaMask - ONLY from MetaMask official website.
          </Text>

          <Text mt="3" color="#FF0000" fontSize="md" textAlign={"center"}>
            3. Import your account using your Private Key.
          </Text>

          <Text mt="3" color="#FF0000" fontSize="md" textAlign={"center"}>
            4. Then go to "Claim Dashboard" and connect your MetaMask account.
          </Text>
        </>
      );
    }

    if (!userClaimed) {
      return (
        <>
          <Button onClick={onOpen} colorScheme="blackAlpha">
            Export Your Wallet
          </Button>
          <Text mt="10" color="black" as="b" fontSize="xl" textAlign={"center"}>
            Congratulations {userData["FullName"]}, you can claim NOW{" "}
            <Text color="#0083FD" as="span">
              {(
                BigNumber.from(userData["20%TokenReleaseAtTGE"]) /
                10 ** 18
              ).toLocaleString()}{" "}
            </Text>{" "}
            out of your{" "}
            <Text color="#0083FD" as="span">
              {(
                BigNumber.from(userData["TotalQtyTokenAirdrop"]) /
                10 ** 18
              ).toLocaleString()}{" "}
            </Text>{" "}
            tokens.
          </Text>
          <Text mt="10" color="black" fontSize="md" textAlign={"center"}>
            Press the button below “Claim Your Tokens” to be airdropped into
            your wallet on the 1st of the month automatically.
            <br />
            The remaining tokens will be unlocked and sent to your claim
            dashboard
            <br />
            every week based on the vesting schedule above.
          </Text>

          <Button
            onClick={claimTokens}
            bgColor="#0066ff"
            color="white"
            mt="10"
            size="lg"
            _hover={{ bgColor: "#0052cc" }}
          >
            {
              loadingButton ? <Spinner size="sm" /> : "Claim Your Tokens"
            }
          </Button>
        </>
      );
    }
  }

  async function claimTokens() {
    try {
      if (!userData) {
        return;
      }

      setLoadingButton(true)

      const claimedAmount = String(userData["20%TokenReleaseAtTGE"]);
      const address = userAddress;
      const email = userData["EmailAddress"];

      await writeData(email, address, claimedAmount);
      setClickClaimed(true);

      setLoadingButton(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function readData(email) {
    try {
      const response = await fetch(sheetDB);
      const data = await response.json();

      const user = data.find((user) => user.Email === email);
      const isClaimed = user ? true : false;

      setUserClaimed(isClaimed);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    readData(email);
    // console.log("Claimed: ", userClaimed);
  }, [fetchedEmail, clickClaimed]);

  async function writeData(email, address, claimedAmount) {
    try {
      if (userClaimed) {
        return;
      }

      await fetch(sheetDB, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "Timestamp": formatDate(new Date()),
          "Email": email,
          "Address": address,
          "Total_Qty_Token _Airdrop": userData["TotalQtyTokenAirdrop"] / 10 ** 18,
          "20% _Token_Release_at_TGE*": claimedAmount / 10 ** 18,
          "80%_over_78_weeks": (userData["TotalQtyTokenAirdrop"] - claimedAmount) / 10 ** 18,
        }),
      });
      // const data = await response.json();
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        if (!showConnectEmbed) {
          const userEmail = await activeWallet.getEmail();
          const userAddress = await activeWallet.getAddress();

          setUserAddress(userAddress);
          setEmail(userEmail);

          const user = findUserByEmail(userEmail);
          if (user) {
            setUserData(user);
            setFetchedEmail(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmail();
  }, [showConnectEmbed, activeWallet]);

  const findUserByEmail = (userEmail) => {
    try {
      const user2016 = data2016.find(
        (user) => user.EmailAddress.toLowerCase() === userEmail.toLowerCase()
      );
      const user2020 = data2020.find(
        (user) => user.EmailAddress.toLowerCase() === userEmail.toLowerCase()
      );

      return user2016 || user2020;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <>
      <Box id="claim-tokens">
        {/* <main className={styles.main}> */}
        <Center mt="10" fontFamily={"Comfortaa"} px="5">
          <VStack>
            <Text
              as="b"
              fontSize={"5xl"}
              textAlign={"center"}
              mb="10"
              color="#0083FD"
            >
              Claim Your Blue Social Tokens
            </Text>
            {showConnectEmbed ? (
              // <div className={styles.centeredFlex}>
              <VStack>
                <Text mb="10" textColor={"black"} textAlign={"center"}>
                  To claim your tokens we require you to use the email
                  associated with your Republic Account.
                  <br />
                  When signing up, we will automatically generate a
                  non-custodial wallet for us to Airdrop the tokens to.
                </Text>
                <br />
                <Text mb="10" textColor={"black"} textAlign={"center"}>
                  Release Schedule: 100% vesting 1.5 years (1.28%
                  unlocked per week)
                </Text>
                <ConnectEmbed />
              </VStack>
            ) : (
              // </div>
              <>
                <ConnectWallet />
                {userData ? (
                  renderWhatToDisplay()
                ) : (
                  <>
                    <Text
                      mt="10"
                      color="black"
                      as="b"
                      fontSize="xl"
                      textAlign={"center"}
                    >
                      OOPS!
                      <br />
                    </Text>
                    <Text color="black" fontSize="md" textAlign={"center"}>
                      It looks like your email{" "}
                      <Text color="#0083FD" as="span">
                        {email}
                      </Text>{" "}
                      is not on our BlueList!
                      <br />
                      <br />
                      If you think this is a mistake, reach out to{" "}
                      <Text color="#0083FD" as="span">
                        tokensalesupport@blue.social
                      </Text>
                      .
                    </Text>
                  </>
                )}
              </>
            )}
          </VStack>
        </Center>
        {/* </main> */}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Your Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width={"100%"}>
              <AspectRatio maxW="560px" ratio={1}>
                <iframe src="https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=d3ef52c9a18c17eba1e1fc43d862671c" allow="clipboard-read; clipboard-write" />
              </AspectRatio>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
