import React from "react";
import "./Banner.css";
import BannerImg from "../../assets/img/banner.png";
const Banner = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('claim-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-text">
        <h1>
        WE ARE GOING LIVE SOON. CLAIM YOUR TOKENS TODAY!
        </h1>
        <p>
        Thank you for supporting us in our Republic.co/blue crowdfunding campaign in 2020. We did not forget about you -  your support means the world to us helping our team fulfill the vision for the Blue Social Network. 
        <br/><br/>
        Here’s a gift from us to you. Claim your FREE tokens today! 
        <br/><br/>
        Blue Social is the worlds first Socialize-to-Earn platform that uses Self-Sovereign Digital Identities and lets you earn rewards and make money through meeting others [and other simple social actions].
        </p>
        {/* <h3>And it's EASY to buy the token in our PRESALE.</h3> */}
        <div className="btn-container">
          <a onClick={scrollToSection} className="blue-btn">
            Claim Now
          </a>
          <a href="https://blue-social.gitbook.io/blue-social-white-paper/" target="_blank" className="simple-btn">
            Whitepaper
          </a>
        </div>
      </div>
      <div className="banner-img">
        <img src={BannerImg} alt="" />
      </div>
    </div>
  );
};

export default Banner;
